
export const defaultUser = {
  userManagement: false,
  approvalUser: false,
  editUser: false,
  deleteUser: false,
  productsInfo: true,
  addBrand: true,
  editBrand: false,
  approvalBrand: false,
  deleteBrand: false,
  addCategory: true,
  editCategory: false,
  approvalCategory: false,
  deleteCategory: false,
  addSupplier: true,
  editSupplier: false,
  approvalSupplier: false,
  deleteSupplier: false,
  addUnit: true,
  editUnit: false,
  approvalUnit: false,
  deleteUnit: false,
  addOrigin: true,
  editOrigin: false,
  approvalOrigin: false,
  deleteOrigin: false,
  addProduct: true,
  editProduct: false,
  approvalProduct: false,
  deleteProduct: false,
  purchase: true,
  addPurchase: true,
  managePurchase: true,
  editPurchase: false,
  deletePurchase: false,
  sales: true,
  addCustomer: true,
  editCustomer: false,
  approvalCustomer:false,
  deleteCustomer: false,
  addSales: true,
  manageSales: true,
  editSales: false,
  deleteSales: false,
  accountsInfo: true,
  addExpenseHead: true,
  editExpenseHead: false,
  approvalExpenseHead:false,
  deleteExpenseHead: false,
  addIncomeHead: true,
  approvalIncomeHead:false,
  editIncomeHead: false,
  deleteIncomeHead: false,
  addBankAccount: true,
  approvalBankAccount: false,
  editBankAccount: false,
  deleteBankAccount: false,
  addLoanAccount: true,
  approvalLoanAccount: false,
  editLoanAccount: false,
  deleteLoanAccount: false,
  addOpeningCapital: true,
  approvalOpeningCapital:false,
  editOpeningCapital: false,
  deleteOpeningCapital: false,
  transactions: true,
  addIncome: true,
  approvalIncome: false,
  editIncome: false,
  deleteIncome: false,
  addExpense: true,
  approvalExpense:false,
  editExpense: false,
  deleteExpense: false,
  addCollection: true,
  approvalCollection:false,
  editCollection: false,
  deleteCollection: false,
  addPayment: true,
  approvalPayment:false,
  editPayment: false,
  deletePayment: false,
  addBankDeposit: true,
  approvalBankDeposit:false,
  editBankDeposit: false,
  deleteBankDeposit: false,
  addBankWithdraw: true,
  approvalBankWithdraw:false,
  editBankWithdraw: false,
  deleteBankWithdraw: false,
  addLoanTaken: true,
  approvalLoanTaken:false,
  editLoanTaken: false,
  deleteLoanTaken: false,
  addLoanReturn: true,
  approvalLoanReturn:false,
  editLoanReturn: false,
  deleteLoanReturn: false,
  reports: true,
  cashStatement: true,
  bankStatement: true,
  balanceSheet: false,
  profitSheet: false,
  currentStock: true,
  stockLedger: true,
  customerDueReport: true,
  customerLedgerReport: true,
  supplierDueReport: true,
  supplierLedgerReport: true,
  loanDueReport: true,
  loanLedgerReport: true,
  invoiceWiseProfit: false,
  showCostInInvoice: false,
  profitSheetAdvance:false,
}



export const superAdmin = {
  userManagement: true,
  editUser: true,
  approvalUser: true,
  deleteUser: true,
  productsInfo: true,
  addBrand: true,
  editBrand: true,
  approvalBrand: true,
  deleteBrand: true,
  addCategory: true,
  editCategory: true,
  approvalCategory: true,
  deleteCategory: true,
  addSupplier: true,
  editSupplier: true,
  approvalSupplier: true,
  deleteSupplier: true,
  addUnit: true,
  editUnit: true,
  approvalUnit: true,
  deleteUnit: true,
  addOrigin: true,
  editOrigin: true,
  approvalOrigin: true,
  deleteOrigin: true,
  addProduct: true,
  editProduct: true,
  approvalProduct: true,
  deleteProduct: true,
  purchase: true,
  addPurchase: true,
  managePurchase: true,
  editPurchase: true,
  deletePurchase: true,
  sales: true,
  addCustomer: true,
  editCustomer: true,
  approvalCustomer: true,
  deleteCustomer: true,
  addSales: true,
  manageSales: true,
  editSales: true,
  deleteSales: true,
  accountsInfo: true,
  addExpenseHead: true,
  editExpenseHead: true,
  approvalExpenseHead: true,
  deleteExpenseHead: true,
  addIncomeHead: true,
  editIncomeHead: true,
  approvalIncomeHead: true,
  deleteIncomeHead: true,
  addBankAccount: true,
  editBankAccount: true,
  approvalBankAccount:true,
  deleteBankAccount: true,
  addLoanAccount: true,
  editLoanAccount: true,
  approvalLoanAccount:true,
  deleteLoanAccount: true,
  addOpeningCapital: true,
  editOpeningCapital: true,
  approvalOpeningCapital:true,
  deleteOpeningCapital: true,
  transactions: true,
  addIncome: true,
  editIncome: true,
  approvalIncome:true,
  deleteIncome: true,
  addExpense: true,
  editExpense: true,
  approvalExpense:true,
  deleteExpense: true,
  addCollection: true,
  editCollection: true,
  approvalCollection:true,
  deleteCollection: true,
  addPayment: true,
  editPayment: true,
  approvalPayment:true,
  deletePayment: true,
  addBankDeposit: true,
  editBankDeposit: true,
  approvalBankDeposit:true,
  deleteBankDeposit: true,
  addBankWithdraw: true,
  editBankWithdraw: true,
  approvalBankWithdraw:true,
  deleteBankWithdraw: true,
  addLoanTaken: true,
  editLoanTaken: true,
  approvalLoanTaken:true,
  deleteLoanTaken: true,
  addLoanReturn: true,
  editLoanReturn: true,
  approvalLoanReturn:true,
  deleteLoanReturn: true,
  reports: true,
  cashStatement: true,
  bankStatement: true,
  balanceSheet: true,
  profitSheet: true,
  currentStock: true,
  stockLedger: true,
  customerDueReport: true,
  customerLedgerReport: true,
  supplierDueReport: true,
  supplierLedgerReport: true,
  loanDueReport: true,
  loanLedgerReport: true,
  invoiceWiseProfit: true,
  showCostInInvoice: true,
  profitSheetAdvance:true
}

export const userKeyMapping = {
  userManagement: 'User Management',
  editUser: 'Edit User',
  deleteUser: 'Delete User',
  productsInfo: 'Products Info',
  addBrand: 'Add Brand',
  editBrand: 'Edit Brand',
  deleteBrand: 'Delete Brand',
  addCategory: 'Add Category',
  editCategory: 'Edit Category',
  deleteCategory: 'Delete Category',
  addSupplier: 'Add Supplier',
  editSupplier: 'Edit Supplier',
  deleteSupplier: 'Delete Supplier',
  addUnit: 'Add Unit',
  editUnit: 'Edit Unit',
  deleteUnit: 'Delete Unit',
  addOrigin: 'Add Origin',
  editOrigin: 'Edit Origin',
  deleteOrigin: 'Delete Origin',
  addProduct: 'Add Product',
  editProduct: 'Edit Product',
  deleteProduct: 'Delete Product',
  purchase: 'Purchase',
  addPurchase: 'Add Purchase',
  managePurchase: 'Manage Purchase',
  editPurchase: 'Edit Purchase',
  deletePurchase: 'Delete Purchase',
  sales: 'Sales',
  addCustomer: 'Add Customer',
  editCustomer: 'Edit Customer',
  deleteCustomer: 'Delete Customer',
  addSales: 'Add Sales',
  manageSales: 'Manage Sales',
  editSales: 'Edit Sales',
  deleteSales: 'Delete Sales',
  accountsInfo: 'Accounts Info',
  addExpenseHead: 'Add Expense Head',
  editExpenseHead: 'Edit Expense Head',
  deleteExpenseHead: 'Delete Expense Head',
  addIncomeHead: 'Add Income Head',
  editIncomeHead: 'Edit Income Head',
  deleteIncomeHead: 'Delete Income Head',
  addBankAccount: 'Add Bank Account',
  editBankAccount: 'Edit Bank Account',
  deleteBankAccount: 'Delete Bank Account',
  addLoanAccount: 'Add Loan Account',
  editLoanAccount: 'Edit loan Account',
  deleteLoanAccount: 'Delete Loan Account',
  addOpeningCapital: 'Add Opening Capital',
  editOpeningCapital: 'Edit Opening Capital',
  deleteOpening: 'Delete Opening',
  transactions: 'Transactions',
  addIncome: 'Add Income',
  editIncome: 'Edit Income',
  deleteIncome: 'Delete Income',
  addExpense: 'Add Expense',
  editExpense: 'Edit Expense',
  deleteExpense: 'Delete Expense',
  addCollection: 'Add Collection',
  editCollection: 'Edit Collection',
  deleteCollection: 'Delete Collection',
  addPayment: 'Add Payment',
  editPayment: 'Edit Payment',
  deletePayment: 'Delete Payment',
  addBankDeposit: 'Add Bank Deposit',
  editBankDeposit: 'Edit Bank Deposit',
  deleteBankDeposit: 'Delete Bank Deposit',
  addBankWithdraw: 'Add Bank Withdraw',
  editBankWithdraw: 'Edit Bank Withdraw',
  deleteBankWithdraw: 'Delete Bank Withdraw',
  addLoanTaken: 'Add Loan Taken',
  editLoanTaken: 'Edit Loan Taken',
  deleteLoanTaken: 'Delete Loan Taken',
  addLoanReturn: 'Add Loan Return',
  editLoanReturn: 'Edit Loan Return',
  deleteLoanReturn: 'Delete Loan Return',
  reports: 'Reports',
  cashStatement: 'Cash Statement',
  bankStatement: 'Bank Statement',
  balanceSheet: 'Balance Sheet',
  profitSheet: 'Profit Sheet',
  profitSheetAdvance:'Advance Profit Sheet',
  currentStock: 'Current Stock',
  stockLedger: 'Stock Ledger',
  customerDueReport: 'Customer Due Report',
  customerLedgerReport: 'Customer Ledger Report',
  supplierDueReport: 'Supplier Due Report',
  supplierLedgerReport: 'Supplier Ledger Report',
  loanDueReport: 'Loan Due Report',
  loanLedgerReport: 'Loan Ledger Report',
  invoiceWiseProfit: 'Invoice Wise Profit',
};