import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import DashboardHome from './DashboardHome';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { MdOutlineArrowDropUp, MdAdUnits, MdTripOrigin, MdManageHistory, MdOutlineManageAccounts } from 'react-icons/md';
import { RxDashboard } from 'react-icons/rx';
import { FaRegUser } from 'react-icons/fa';
import { RiProductHuntLine } from 'react-icons/ri';
import { BsCartPlus } from 'react-icons/bs';
import { TbBrandBeats, TbCategory2 } from 'react-icons/tb';
import { BiPurchaseTag, BiPurchaseTagAlt } from 'react-icons/bi';
import { AiOutlineShoppingCart, AiOutlineAccountBook } from 'react-icons/ai';
import { TbMoneybag, TbReportSearch, TbShoppingCartPlus } from 'react-icons/tb';
import { useSelector } from 'react-redux';


const Dashboard = () => {
    const user = useSelector((state) => state.user.user);
    const [open, setOpen] = useState(true);
    const [open2, setOpen2] = useState(true);
    const [open3, setOpen3] = useState(true);
    const [open4, setOpen4] = useState(true);
    const [open5, setOpen5] = useState(true);
    const [open6, setOpen6] = useState(true);

    return (
        <div class="drawer drawer-mobile mt-16">
            <input id="my-drawer-2" type="checkbox" class="drawer-toggle" />
            <div class="drawer-content text-center">
                <div className='w-full mx-auto'>
                    <Outlet />
                </div>
            </div>
            <div class="drawer-side h-screen mt-px">
                <label for="my-drawer-2" class="drawer-overlay"></label>
                <ul class="menu p-4 overflow-y-scrool w-60 bg-red-600 text-white text-xs font-semibold">
                    {/* <!-- Sidebar content here --> */}
                    <li className='bg-white text-red-600 shadow-xl mb-2'><Link to='/'><RxDashboard />Dashboard</Link></li>
                    {user?.userManagement && <li className='bg-white text-red-600 shadow-xl mb-2'><Link to='/adduser'><FaRegUser />User Management</Link></li>}
                    {user?.productsInfo && <div class="collapse mb-2">
                        <input onClick={() => setOpen(!open)} type="checkbox" class="peer" />
                        <div class="collapse-title bg-white text-red-600 peer-checked:bg-gray-200 peer-checked:text-red-content flex justify-between">
                            <RiProductHuntLine className='my-auto text-xl' /> Products Info{open ? <span><MdOutlineArrowDropDown className='text-2xl' /></span> : <span><MdOutlineArrowDropUp className='text-2xl' /></span>}
                        </div>
                        <div class="collapse-content">
                            {user?.addBrand && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addbrand'><TbBrandBeats />Add Brand</Link></li>}
                            {user?.addCategory && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addcategory'><TbCategory2 />Add Category</Link></li>}
                            {user?.addUnit && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addunit'><MdAdUnits />Add Unit</Link></li>}
                            {user?.addOrigin && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addorigin'><MdTripOrigin />Add Origin</Link></li>}
                            {user?.addProduct && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addproduct'><RiProductHuntLine />Add A Product</Link></li>}
                        </div>
                    </div>}
                    {user?.purchase && <div class="collapse mb-2">
                        <input onClick={() => setOpen2(!open2)} type="checkbox" class="peer" />
                        <div class="collapse-title bg-white text-red-600 peer-checked:bg-gray-200 peer-checked:text-red-content flex justify-between">
                            <BiPurchaseTagAlt className='text-xl' /> Purchase{open2 ? <span><MdOutlineArrowDropDown className='text-2xl' /></span> : <span><MdOutlineArrowDropUp className='text-2xl' /></span>}
                        </div>
                        <div class="collapse-content">
                        {user?.addSupplier && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addsupplier'><BiPurchaseTag />Add Supplier</Link></li>}
                            {user?.addPurchase && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addpurchase'><TbShoppingCartPlus />Add A Purchase</Link></li>}
                            {user?.addPurchase && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addpospurchase'><TbShoppingCartPlus />Add Pos Purchase</Link></li>}
                            {user?.managePurchase && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/managepurchase'><MdManageHistory />Manage Purchase</Link></li>}
                            {user?.addPurchase && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addpurchaseorder'><TbShoppingCartPlus />Add Purchase Order</Link></li>}
                            {user?.managePurchase && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/managepurchaseorder'><MdManageHistory />Manage Purchase Order</Link></li>}
                        </div>
                    </div>}
                    {user?.sales && <div class="collapse mb-2">
                        <input onClick={() => setOpen3(!open3)} type="checkbox" class="peer" />
                        <div class="collapse-title bg-white text-red-600 peer-checked:bg-gray-200 peer-checked:text-red-content flex justify-between">
                            <AiOutlineShoppingCart className='text-xl' />Sales{open3 ? <span><MdOutlineArrowDropDown className='text-2xl' /></span> : <span><MdOutlineArrowDropUp className='text-2xl' /></span>}
                        </div>
                        <div class="collapse-content">
                            {/* <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addpurchase'>Add Sales</Link></li> */}
                            {user?.addCustomer && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addmaincustomer'><MdOutlineManageAccounts />Add Customer</Link></li>}
                            {user?.addSales && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/adddefaultsales'><BsCartPlus />Add Sales</Link></li>}
                            {user?.addSales && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addpossales'><BsCartPlus />Add Pos Sales</Link></li>}
                            {user?.manageSales && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/managedefaultsales'><MdManageHistory />Manage Sales</Link></li>}
                            {/* <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/saleslist'>Manage Sales</Link></li> */}
                        </div>
                    </div>}
                    {user?.accountsInfo && <div class="collapse mb-2">
                        <input onClick={() => setOpen4(!open4)} type="checkbox" class="peer" />
                        <div class="collapse-title bg-white text-red-600 peer-checked:bg-gray-200 peer-checked:text-red-content flex justify-between">
                            <AiOutlineAccountBook className='text-xl' /> Accounts Info{open4 ? <span><MdOutlineArrowDropDown className='text-2xl' /></span> : <span><MdOutlineArrowDropUp className='text-2xl' /></span>}
                        </div>
                        <div class="collapse-content">
                            {/* <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addpurchase'>Add Sales</Link></li> */}
                            {user?.addExpense && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addexpensehead'>Add Expense Head</Link></li>}
                            {user?.addIncome && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addincomehead'>Add Income Head</Link></li>}
                            {user?.addBankAccount && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addbankaccount'>Add Bank Account</Link></li>}
                            {user?.addLoanAccount && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addloanaccount'>Add Loan Account</Link></li>}
                            {user?.addOpeningCapital && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addopeningcapital'>Add Opening Capital</Link></li>}
                        </div>
                    </div>}
                    {user?.transactions && <div class="collapse mb-2">
                        <input onClick={() => setOpen5(!open5)} type="checkbox" class="peer" />
                        <div class="collapse-title bg-white text-red-600 peer-checked:bg-gray-200 peer-checked:text-red-content flex justify-between">
                            <TbMoneybag className="text-xl" /> Transactions{open5 ? <span><MdOutlineArrowDropDown className='text-2xl' /></span> : <span><MdOutlineArrowDropUp className='text-2xl' /></span>}
                        </div>
                        <div class="collapse-content">
                            {/* <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addpurchase'>Add Sales</Link></li> */}
                            {user?.addIncome && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addincome'>Add Income</Link></li>}
                            {user?.addExpense && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addexpense'>Add Expense</Link></li>}
                            {user?.addCollection && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addcollection'>Add Collection</Link></li>}
                            {user?.addPayment && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addpayment'>Add Payment</Link></li>}
                            {user?.addBankDeposit && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addbankdeposit'>Add Bank Deposit</Link></li>}
                            {user?.addBankWithdraw && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addbankwithdraw'>Add Bank Withdraw</Link></li>}
                            {user?.addLoanTaken && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addloantaken'>Add Loan Taken</Link></li>}
                            {user?.addLoanReturn && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/addloanreturn'>Add Loan Return</Link></li>}
                        </div>
                    </div>}
                    {/* <li className='bg-white text-red-600 shadow-xl mb-2'><Link to='/bookinglist'>Pending Bookings</Link></li>
                    <li className='bg-white text-red-600 shadow-xl mb-2'><Link to='/completedlist'>Completed Bookings</Link></li>
                    <li className='bg-white text-red-600 shadow-xl mb-2'><Link to='/cancelledlist'>Cancelled Bookings</Link></li> */}
                    {/* <li className='bg-white text-red-600 shadow-xl'><Link to='/addcategory'>All Bookings</Link></li>
                    <li className='bg-white text-red-600 shadow-xl'><Link to='/addcategory'>Pending Bookings</Link></li> */}
                    {user?.reports && <div class="collapse mb-2">
                        <input onClick={() => setOpen6(!open6)} type="checkbox" class="peer" />
                        <div class="collapse-title bg-white text-red-600 peer-checked:bg-gray-200 peer-checked:text-red-content flex justify-between">
                            <TbReportSearch className='text-xl' /> Reports{open6 ? <span><MdOutlineArrowDropDown className='text-2xl' /></span> : <span><MdOutlineArrowDropUp className='text-2xl' /></span>}
                        </div>
                        <div class="collapse-content">
                            {user?.cashStatement && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/cashstatement'>Cash Statement</Link></li>}
                            {user?.bankStatement && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/bankstatement'>Bank Statement</Link></li>}
                            {user?.balanceSheet && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/balancesheet'>Balance Sheet</Link></li>}
                            {user?.profitSheet && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/profitsheet'>Profit Sheet</Link></li>}
                            {user?.profitSheetAdvance && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/profitsheetadvance'>Advance Profit Sheet</Link></li>}
                            {user?.currentStock && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/currentstock'>Current Stock</Link></li>}
                            {user?.stockLedger && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/stockledger'>Stock Ledger</Link></li>}
                            <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/topsellinglessselling'>Best & Less Selling Product</Link></li>
                            <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/stockoutreport'>Stock Out Report</Link></li>
                            {user?.customerDueReport && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/customerduereport'>Customer Due Report</Link></li>}
                            {user?.customerLedgerReport && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/customerledger'>Customer Ledger Report</Link></li>}
                            {user?.supplierDueReport && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/supplierduereport'>Supplier Due Report</Link></li>}
                            {user?.supplierLedgerReport && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/supplierledger'>Supplier Ledger Report</Link></li>}
                            {user?.loanDueReport && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/loanduereport'>Loan Due Report</Link></li>}
                            {user?.loanLedgerReport && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/laonledger'>Loan Ledger Report</Link></li>}
                            {user?.invoiceWiseProfit && <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/invoiceprofit'>Invoice Wise Profit</Link></li>}
                            <li className='bg-white text-red-600 shadow-xl mt-2'><Link to='/reports/barcode'>Bar Code</Link></li>
                        </div>
                    </div>}
                </ul>

            </div>
        </div>
    );
};

export default Dashboard;