import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import Loader from '../../../Shared/Loader';
import { useAuthState, useCreateUserWithEmailAndPassword, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useRef } from 'react';
import auth from '../../../../Firebase/Firebase';
import { signOut } from 'firebase/auth';
import { defaultUser, superAdmin } from '../../../Shared/UserPermissions'



const UserField = ({ }) => {
    const currentUser = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [createUserWithEmailAndPassword] = useCreateUserWithEmailAndPassword(auth);
    // const [user] = useAuthState(auth);
    const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);


    const [users, setUsers] = useState([]);
    const [updateUser, setUpdateUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();


    useEffect(() => {
        fetch('https://demoserver.clearsoftwares.xyz/getusers',{
            headers:{
                // 'authorization':`Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                setUsers(data);
                setLoading(false)
            })
    }, [users, setUsers, updateUser, setUpdateUser, dispatch])

    if (loading) {
        return <Loader />
    }



    const onSubmit = data => {
        const email = data.email;
        const password = data.password;
        const name = data.name;
        const type = data.type;
        const status = data.status;
        const user = currentUser?.email.split('@')[0]

        if (type === "Normal User") {
            fetch('https://demoserver.clearsoftwares.xyz/users', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ ...defaultUser, email, password, name, type, status, user }),
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('User Added')
                    reset();
                })

            const createUser = async () => {
                await createUserWithEmailAndPassword(email, password);
                await signOut(auth);
                // await signInWithEmailAndPassword("ahsan@nbcmotors.net", "123456");
            }
            createUser();
        } else {
            fetch('https://demoserver.clearsoftwares.xyz/users', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ ...superAdmin, email, password, name, type, status, user }),
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('User Added')
                    reset();
                })

            const createUser = async () => {
                await createUserWithEmailAndPassword(email, password);
                await signOut(auth);
                // await signInWithEmailAndPassword("ahsan@nbcmotors.net", "123456");
            }

            createUser();
        }

    }

    const handleDeleteUser = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://demoserver.clearsoftwares.xyz/getusers/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateUser(null);
                })
        }
    };

    const handleupdateUser = (user) => {
        setUpdateUser(user);
    }

    const handleApprove = (user) => {
        const approvedBy = currentUser?.email.split('@')[0];
        fetch(`https://demoserver.clearsoftwares.xyz/getusers/${user._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Brand Approved');
                // window.location.reload(false)
            })
    }

    return (
        <div className='shadow-2xl px-2 pb-5 my-5 text-xs text-xs'>
            <form onSubmit={handleSubmit(onSubmit)} className='container flex flex-col lg:flex-row md:flex-row  mx-auto gap-2 justify-center items-center'>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">User Email</span>
                    </label>
                    <input  {...register("email")} type="text" placeholder="Type User Email Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">User Password</span>
                    </label>
                    <input {...register("password")} type="password" placeholder="Type User Password Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">User Name</span>
                    </label>
                    <input {...register("name")} type="text" placeholder="Type User Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Type</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("type")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Normal User</option>
                        <option>Super Admin</option>
                    </select>
                </div>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Status</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <input disabled={!currentUser?.userManagement} type="submit" value='Add User' className='btn bg-red-600 text-white lg:mt-8 md:mt-8' />
            </form>

            {
                users.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>User Email</th>
                                <th className='bg-red-600 text-white normal-case'>User Password</th>
                                <th className='bg-red-600 text-white normal-case'>User Name</th>
                                <th className='bg-red-600 text-white normal-case'>User Type</th>
                                <th className='bg-red-600 text-white normal-case'>Status</th>
                                <th className='bg-red-600 text-white normal-case'>Added By</th>
                                <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                <th className='bg-red-600 text-white normal-case'>Permissions</th>
                                <th className='bg-red-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                users.map((user, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{user.email}</td>
                                    <td>******</td>
                                    <td>{user?.name}</td>
                                    <td>{user?.type}</td>
                                    <td> <button className={`btn btn-xs normal-case text-xs ${user.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{user?.status}</button> </td>
                                    <td><button className='btn btn-xs btn-primary text-white normal-case'>{user?.user ? user.user : "User"}</button></td>
                                    {currentUser?.approvalUser ? <td>{user?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{user?.approvedBy}</button> : <button onClick={() => handleApprove(user)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                        <td>{user.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{user.approvedBy}</button> : <button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                    {currentUser?.editUser && <td><button><label for="update-modal" onClick={() => handleupdateUser(user)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                    {currentUser?.deleteUser && <td><button onClick={() => handleDeleteUser(user._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
            {
                updateUser && <UpdateModal setUpdateUser={setUpdateUser} updateUser={updateUser}></UpdateModal>
            }
        </div>
    );
};

export default UserField;