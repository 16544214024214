import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import ViewInvoice from './ViewInvoice';
import UpdateBikeSalesInvoice from '../ManageSales/UpdateBikeSalesInvoice';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSales } from '../../../../features/SalesSlice/SalesSlice';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import useViewInvoice from '../../../../hooks/useViewInvoice';

const BikeSalesList = () => {
    const userAc = useSelector((state) => state.user.user);
    const [sales, setSales] = useState([]);
    const [salesModal, setSalesModal] = useState(null);
    const [updateModal, setUpdateModal] = useState(null);
    const dispatch = useDispatch();
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    console.log(sales);

    // const success=useViewInvoice("getsales",salesModal);
    // console.log(success);

    const date = new Date();
    const today = format(date, "yyyy-MM-dd")

    useEffect(() => {
        fetch('https://demoserver.clearsoftwares.xyz/todaysales')
            .then(res => res.json())
            .then(data => {
                setSales(data);
            })
    }, []);

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        console.log(startDate, endDate);
        const url = `https://demoserver.clearsoftwares.xyz/filteredsales?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const filter = data.filter(sale => sale?.products[0]?.code === "903871286300")
                console.log(filter);
                setSales(data);
            })


    }

    const handleAddProductToInvoice = (sales) => {
        setSalesModal(sales)
        const viewedUser = userAc?.email.split('@')[0]
        const currentUser = sales?.user;
        if (viewedUser !== currentUser) {
            fetch(`https://demoserver.clearsoftwares.xyz/getsales/${sales?._id}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ viewedUser}),
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })
        }
    }
    const handleUpdateSales = (sales) => {
        dispatch(updateSales(sales));

    }
    const handleDeleteProduct = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://demoserver.clearsoftwares.xyz/getsales/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setTimeout(() => { window.location.reload(); }, 3000);
                })
        }
    };

    const handleSearchResult = (event) => {
        const searchText = event.target.value;
        const match = sales.filter(sale => sale.salesCustomer.includes(searchText) || sale.salesCustomer.toLowerCase().includes(searchText)
            || sale.salesCustomer.toUpperCase().includes(searchText) || sale.salesInvoice.includes(searchText)
            || sale.salesInvoice.toLowerCase().includes(searchText) || sale.salesInvoice.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const totalPayment = isSearching ? searchResult.reduce((total, currentValue) => total + parseInt(currentValue?.payment), 0) : sales.reduce((total, currentValue) => total + parseInt(currentValue?.payment), 0);
    const totalAmount = isSearching ? searchResult.reduce((total, currentValue) => total + parseInt(currentValue?.grandTotal), 0) : sales.reduce((total, currentValue) => total + parseInt(currentValue?.grandTotal), 0);
    const totalDue = isSearching ? searchResult.reduce((total, currentValue) => total + parseInt(currentValue?.dueAmount), 0) : sales.reduce((total, currentValue) => total + parseInt(currentValue?.dueAmount), 0);

    return (
        <div className='w-full px-4 pb-5 my-5'>
        <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Manage Sales</h1>
            <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center lg:items-right'>
                <div class="form-control w-full lg:w-40 max-w-xs mx-auto lg:mx-0 lg:mr-2">
                    <label class="label">
                        <span class="label-text">Starting Date</span>
                    </label>
                    <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                </div>
                <div class="form-control w-full lg:w-40 max-w-xs mx-auto lg:mx-0 lg:mr-2">
                    <label class="label">
                        <span class="label-text">End Date</span>
                    </label>
                    <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                </div>
                <input type='submit' value='Search' className='btn btn-md btn-error text-white mt-4 lg:mt-9 lg:ml-2' />
            </form>
            <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Sales List Sheet`}
                    sheet={`sales-sheet`}
                    buttonText="Export to exel" />
                {/* <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button> */}
            </div>
            <div className='flex justify-end items-center my-4'>
                <div class="form-control w-60">
                    <input onChange={handleSearchResult} type="text" placeholder="Search Sales" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                </div>
            </div>
            <div className='overflow-auto'>
            <table id='table-to-xls' class="table w-1/2 mx-auto mt-4 text-xs">
                <thead className='text-center'>
                    <tr>
                        <th className='bg-red-600 text-white normal-case'>SL#</th>
                        <th className='bg-red-600 text-white normal-case'>Sales Date</th>
                        <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                        <th className='bg-red-600 text-white normal-case'>Customer</th>
                        <th className='bg-red-600 text-white normal-case'>Total Amount</th>
                        <th className='bg-red-600 text-white normal-case'>Total Payment</th>
                        <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                        <th className='bg-red-600 text-white normal-case'>Added By</th>
                        {userAc.type === "Super Admin" && <th className='bg-red-600 text-white normal-case'>Reviewed</th>}
                        <th className='bg-red-600 text-white normal-case'>Action</th>
                        <th className='bg-red-600 text-white normal-case'>Action</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {isSearching ? searchResult.map((sales, index) => <tr className=''>
                        <td>{index + 1}</td>
                        <td>{sales.salesDate}</td>
                        <td><label for="view-modal" onClick={() => handleAddProductToInvoice(sales)} style={{ width: '150px', height: '50px' }} className={`text-blue-600 font-bold cursor-pointer`}>{sales?.salesInvoice}</label></td>
                        <td>{sales.salesCustomer}</td>
                        <td>{sales.grandTotal}</td>
                        <td>{sales.payment}</td>
                        <td>{sales.dueAmount}</td>
                        <td><button className='btn btn-xs btn-primary text-white normal-case'>{sales?.user ? sales.user : "User"}</button></td>
                        {userAc.type === "Super Admin" && <td>{sales?.viewedUser ? <button className='btn btn-xs btn-info text-white normal-case'>{sales.viewedUser}</button> : <button className='btn btn-xs btn-warning text-white normal-case'>Not Viewed</button>}</td>}
                        {userAc?.editSales && <td><Link to={sales.type?`/updatepossales/edit/${sales._id}`:`/managedefaultsales/edit/${sales._id}`} onClick={() => handleUpdateSales(sales)} className='btn btn-info btn-xs'><FaEdit /></Link></td>}
                        {userAc?.deleteSales && <td><button onClick={() => handleDeleteProduct(sales._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                    </tr>) :
                        sales?.map((sales, index) => <tr className=''>
                            <td>{index + 1}</td>
                            <td>{sales.salesDate}</td>
                            <td><label for="view-modal" onClick={() => handleAddProductToInvoice(sales)} style={{ width: '150px', height: '50px' }} className={`text-blue-600 font-bold cursor-pointer`}>{sales?.salesInvoice}</label></td>
                            <td>{sales.salesCustomer}</td>
                            <td>{sales.grandTotal}</td>
                            <td>{sales.payment}</td>
                            <td>{sales.dueAmount}</td>
                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{sales?.user ? sales.user : "User"}</button></td>
                            {userAc.type === "Super Admin" && <td>{sales?.viewedUser ? <button className='btn btn-xs btn-info text-white normal-case'>{sales.viewedUser}</button> : <button className='btn btn-xs btn-warning text-white normal-case'>Not Viewed</button>}</td>}
                            {userAc?.editSales && <td><Link to={sales.type?`/updatepossales/edit/${sales._id}`:`/managedefaultsales/edit/${sales._id}`} onClick={() => handleUpdateSales(sales)} className='btn btn-info btn-xs'><FaEdit /></Link></td>}
                            {userAc?.deleteSales && <td><button onClick={() => handleDeleteProduct(sales._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                        </tr>)
                    }

                </tbody>
                <tfoot className='mb-12 text-center text-xs'>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='text-sm'>Total</td>
                    <td className='text-sm ml-4'>{totalAmount}</td>
                    <td className='text-sm'>{totalPayment}</td>
                    <td className='text-sm'>{totalDue}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tfoot>
            </table>
            </div>
            <div className='w-8/12'>
                {
                    salesModal && <ViewInvoice setSalesModal={setSalesModal} salesModal={salesModal}></ViewInvoice>
                }
            </div>
            <div className='w-8/12'>
                {
                    updateModal && <UpdateBikeSalesInvoice setUpdateModal={setUpdateModal} updateModal={updateModal}></UpdateBikeSalesInvoice>
                }
            </div>
        </div>
    );
};

export default BikeSalesList;