import { useState, useEffect } from 'react';

const usePaginationSearch = (url) => {
    const [items, setitems] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [limit, setLimit] = useState(20);
    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalItem, setTotalItem] = useState();

    useEffect(() => {
        fetch(`${url}?search=${searchText}&page=${pageNo}&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setitems(data.total);
                setPageCount(Math.ceil(data.document / limit))
                setLoading(false)
                setTotalItem(data.myLength);
            })
    }, [limit])

    const handleSearchResult = (event) => {
        const searchText = event.target.value
        fetch(`${url}?search=${searchText}&page=1&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setitems(data.total);
                if (searchText.length) {
                    setPageCount(Math.ceil(data.total.length / limit))
                } else {
                    setPageCount(Math.ceil(data.document / limit))
                }
                setLoading(false)
            })
        // const match = products.filter(product => product.name.includes(searchText)
        //     || product.name.toLowerCase().includes(searchText)
        //     || product.name.toLowerCase().includes(searchText));
        // setSearchResult(match);
        // setIsSearching(true);
    }

    const handleLimit = (event) => {
        const limited = event.target.value === "All" ? "All" : parseInt(event.target.value);
        setLimit(limited);
    }
    // console.log(categories);

    const handlePageClick = (data) => {
        console.log(data.selected);
        fetch(`${url}?search=${searchText}&page=${data.selected + 1}&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setitems(data.total);
                setPageCount(Math.ceil(data.document / limit))
                setLoading(false)
            })
        setPageNo(data.selected + 1);

    }

    const refetchData = (newPage) => {
        setPageNo(newPage?newPage:1); // Set the new page number
        setLoading(true); // Set loading to true to indicate data fetching
      
        // Fetch data with the new page number
        fetch(`${url}?search=${searchText}&page=${newPage?newPage:1}&limit=${limit}`)
          .then((res) => res.json())
          .then((data) => {
            setitems(data.total);
            setPageCount(Math.ceil(data.document / limit));
            setLoading(false);
          });
      };
      

    return { items, pageCount,totalItem, limit, pageNo, searchText,
         loading, handleSearchResult, handleLimit, handlePageClick,refetchData };
};

export default usePaginationSearch;