// import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import useCurrentStock from '../../../../hooks/useCurrentStock';
import { useRef } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddPurchaseOrder = () => {
    const userAc = useSelector((state) => state.user.user);
    const products = useSelector((state) => state.purchaseOrder.products);
    const { stockData, isLoading } = useCurrentStock();
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState();
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const purchaseOrderNo = `PO${(purchaseOrders?.length + 1).toString().padStart(5, '0')}`;
    const navigate = useNavigate();

    const totalRef = useRef('');
    const invoiceRef = useRef('');
    const dateRef = useRef('')

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    console.log(products)

    useEffect(() => {
        fetch('https://demoserver.clearsoftwares.xyz/getpurchaseorders')
            .then(res => res.json())
            .then(data => {
                setPurchaseOrders(data);
                // setLoading(false)
            })
    }, [])

    // State to store the modified array
    const [dataArray, setDataArray] = useState(products);

    // Function to calculate the default quantity
    const calculateDefaultQuantity = (minimum) => {
        return minimum * 2;
    };

    // Function to calculate the default total
    const calculateDefaultTotal = (defaultQuantity, purchase) => {
        return defaultQuantity * purchase;
    };

    // Set default values for quantity and total when the component mounts
    useEffect(() => {
        setDataArray(prevDataArray => {
            return prevDataArray.map(item => {
                const defaultQuantity = calculateDefaultQuantity(item.minimum);
                const defaultTotal = calculateDefaultTotal(defaultQuantity, item.purchase);
                return {
                    ...item,
                    quantity: item.quantity || defaultQuantity,
                    total: item.total || defaultTotal,
                };
            });
        });
    }, []);

    // Function to update the dataArray whenever the input changes
    const handleInputChange = (index, field, value) => {
        setDataArray(prevDataArray => {
            const newDataArray = [...prevDataArray];
            newDataArray[index] = {
                ...newDataArray[index],
                [field]: value,
                total: field === 'quantity' ? value * newDataArray[index].purchase : newDataArray[index].quantity * value,
            };
            return newDataArray;
        });
    };

    // Function to remove an item from the dataArray
    const handleRemoveItem = index => {
        setDataArray(prevDataArray => {
            const newDataArray = [...prevDataArray];
            newDataArray.splice(index, 1);
            return newDataArray;
        });
    };
    const totalValue = dataArray.reduce((total, item) => total + (item.total || 0), 0);
    // Send the modified data to the server (e.g., on form submission)


    // New Product Functions

    const handleSearchResult = (event) => {
        const searchText = event.target.value.toLowerCase(); // Convert search text to lowercase
        const match = stockData.filter(product =>
            product.name.toLowerCase().includes(searchText) ||
            product.code.includes(searchText)
        );
        setSearchText(searchText); // Update search text state
        setSearchResult(match);
        setIsSearching(true);
    }

    // const handleSearchResult = (event) => {
    //     setSearchText(event.target.value)
    //     const match = stockData.filter(product => product.name.includes(searchText) ||
    //         product.name.toLowerCase().includes(searchText) || product.name.toUpperCase().includes(searchText));
    //     setSearchResult(match);
    //     setIsSearching(true);
    // }

    const handleAddProductToInvoice = (product) => {
        console.log(product)
        setTimeout(() => {
            setDataArray(prevDataArray => {
                const defaultQuantity = calculateDefaultQuantity(product.minimum, product.purchase);
                const defaultTotal = calculateDefaultTotal(defaultQuantity, product.purchase);

                return [...prevDataArray, { ...product, quantity: defaultQuantity, total: defaultTotal }];
            });
        }, 1000);

        setIsSearching(false)
        setSearchText('');
    }

    const sendDataToServer = () => {
        // Your server request logic here...
        const options = {
            timeZone: 'Asia/Dhaka',
            hour12: false,
        };
        const currentTime = new Date().toLocaleString('en-US', options);
        const date = dateRef.current.value;
        const invoice = invoiceRef.current.value;
        const totalAmount = parseFloat(totalRef.current.innerText);
        const user = userAc?.email.split('@')[0];
        const data = { date, invoice, totalAmount,user,currentTime, products: dataArray };
       

        fetch('https://demoserver.clearsoftwares.xyz/purchaseorder', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Purchase Order Added')
                navigate("/managepurchaseorder")
                setTimeout(() => { window.location.reload(); }, 3000);
            })
    };


    return (
        <div className="mx-2 py-4">
            <h1 className="text-lg font-bold mb-4">Product Purchase Order</h1>
            <div className='flex flex-col lg:flex-row lg:gap-2 justify-center items-center lg:justify-start'>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Select Date</span>
                    </label>
                    <input ref={dateRef} type="date" defaultValue={today} placeholder="Date" class="rounded-lg text-xs bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Purchase Order No</span>
                    </label>
                    <input ref={invoiceRef} value={purchaseOrderNo} type="text" placeholder="Type Supplier Name Here" class="rounded-lg text-xs bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
            </div>
            <div className='flex justify-center lg:justify-start items-center my-4'>
                <div class="form-control w-60 mx-auto lg:mx-px">
                    <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Add More Product" class="input text-xs input-bordered border-dark-purple w-full max-w-xs" />
                </div>
            </div>
            <hr />
            <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-60' : "h-8"} w-80 overflow-auto`}>
                {
                    isSearching ? searchResult.map(product =>
                        <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 text-xs p-3 text-white te  shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>) :
                        stockData.map(product =>
                            <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 text-xs p-3 text-white  te  shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>)
                }
            </div>


            <div className='overflow-auto mx-2'>
            <table class="table w-1/2 mx-auto text-xs">
                <thead className='text-center'>
                    <tr>
                        <th className='bg-red-600 text-white normal-case'>SL#</th>
                        <th className='bg-red-600 text-white normal-case'>Product Code</th>
                        <th className='bg-red-600 text-white normal-case'>Product Name</th>
                        <th className='bg-red-600 text-white normal-case'>Minimum Stock</th>
                        <th className='bg-red-600 text-white normal-case'>Current Stock</th>
                        <th className='bg-red-600 text-white normal-case'>Order Quantity</th>
                        <th className='bg-red-600 text-white normal-case'>Unit</th>
                        <th className='bg-red-600 text-white normal-case'>Purchase Rate</th>
                        <th className='bg-red-600 text-white normal-case'>Total</th>
                        <th className='bg-red-600 text-white normal-case'>Action</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        dataArray?.map((product, index) => <tr key={product._id} className='hover'>
                            <td className='h-8'>{index + 1}</td>
                            <td className='h-8'>{product?.code}</td>
                            <td className='text-xs'>{product?.name}</td>
                            <td><input type="number" disabled value={product?.minimum} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-20 max-w-lg" /></td>
                            <td><input type="number" disabled value={product?.stock} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-20 max-w-lg" /></td>
                            <td><input
                                type="number"
                                className="border border-gray-300 px-2 py-1 rounded-md w-full text-center"
                                defaultValue={(product?.minimum) * 2 || 0}
                                onChange={e => handleInputChange(index, 'quantity', parseInt(e.target.value))}
                            /></td>
                             <td className='h-8'>{product?.unit}</td>
                            <td> <input
                                type="number"
                                className="border border-gray-300 px-2 py-1 rounded-md w-full text-center"
                                value={product.purchase || ''}
                                onChange={e => handleInputChange(index, 'purchase', parseFloat(e.target.value))}
                            /></td>
                            <td><input type="number" value={product.total || 0} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-40 max-w-lg" /></td>
                            <td> <button
                                className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md"
                                onClick={() => handleRemoveItem(index)}
                            >
                                Remove
                            </button> </td>
                        </tr>)
                    }

                </tbody>
                <tfoot className='text-center h-2'>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='text-sm font-bold'>Total</td>
                        <td ref={totalRef} className='text-sm font-bold'>{totalValue.toFixed(2)}</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
            </div>
            <button
                className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md mt-4"
                onClick={sendDataToServer}
            >
                Submit Purchase Order
            </button>
        </div>
    );
};

export default AddPurchaseOrder;






