import { createSlice } from "@reduxjs/toolkit"


const initialState={
    brand: [],
    category: [],
    invoices: [],
    collectionInvoices:[],

}

const infoSlice= createSlice({
    name: 'info',
    initialState,
    reducers:{
        editBrand:(state, action)=>{
            state.brand= action.payload;
        },
        editCategory:(state, action)=>{
            state.category= action.payload;
        },
        getDueInvoices:(state, action)=>{
            const onlyAmount= action.payload.filter(inv=>inv.dueAmount!==0)
            state.invoices= onlyAmount;
        },
        getCollectionInvoices:(state, action)=>{
            const selectedInvoices = state.collectionInvoices.find(inv => inv._id === action.payload._id);
            if (!selectedInvoices) {
                const newOne = action.payload;
                state.collectionInvoices.push(newOne);
            }
        },
        removeInvoiceFromList: (state, action) => {
            const selectedInvoices = state.collectionInvoices.filter(inv => inv._id !== action.payload._id);
            state.collectionInvoices = selectedInvoices;
        },
    }
});

export const {editBrand, editCategory,getDueInvoices,getCollectionInvoices,removeInvoiceFromList}= infoSlice.actions;
export default infoSlice.reducer;