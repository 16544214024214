import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "Nazmul Traders",
    fullName: "M/S Nazmul Traders Thai Fabrication",
    dealer: "(Authorized Dealer Of Kai Aluminium, Nassir Glass)",
    address: "Near Adib Super Market, Mawna Chowrasta, Sreepur, Gazipur",
    mobile: "Mobile: 01404010517,01404010518",
    img: logo,
}

export default BusinessInfo;
